.credit-index-chart {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.score-details {
  margin-top: -80px;
}

.score-details h3 {
  font-size: 36px;
  margin: 0;
}

.score-details p {
  font-size: 16px;
  margin: 5px 0;
  color: #666;
}
