.toggle-switch {
  display: flex;
  align-items: center;
  position: relative;
}
.toggle-switch .switch-input-block {
  position: relative;
}
.toggle-switch .switch-input-block .toggle-checkbox {
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 3;
}
.toggle-switch .switch-input-block .toggle-switch-view {
  position: relative;
  background-color: #eaecf0;
  border-radius: 9999px;
  transition: all 0.3s ease;
  padding: 2px;
}
.toggle-switch .switch-input-block .toggle-switch-view .toggle-swithc-glob {
  content: '';
  position: absolute;
  background-color: #fff;
  top: 2px;
  transform: translate(0);
  border-radius: 9999px;
  transition: all 0.2s ease;
}
.toggle-switch
  .switch-input-block
  input[type='checkbox']:checked
  + .toggle-switch-view {
  background-color: #444ce7;
}
.toggle-switch
  .switch-input-block
  input[type='checkbox']:checked
  + .toggle-switch-view
  .toggle-swithc-glob {
  transform: translate(100%);
}
